import { useMemo } from 'react'
import dayjs from 'dayjs'
import { APP_LAUNCH_YEAR } from 'utils/config'
import { monthsOptions } from 'utils/constants'

const currentYear = dayjs().year()
const currentMonth = dayjs().month() + 1 // indexed from 0 ...

export default () => {
  const yearsOptions = useMemo(() => {
    const years = []
    for (let i = APP_LAUNCH_YEAR; i <= currentYear; i++) {
      years.push({ value: i, label: i.toString() })
    }
    return years
  }, [currentYear])

  return { yearsOptions, monthsOptions, currentYear, currentMonth }
}
