import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { cleanupRequestStatus, getUsersBillings } from 'features/billingsSlice'
import { Alert, Box, Link, Typography } from '@mui/joy'
import { Warning } from '@mui/icons-material'
import routes from 'routes'
import useDateFilters from 'hooks/useDateFilters'
import Breadcrumbs from 'components/Breadcrumbs'
import usePagination from 'hooks/usePagination'
import BaseTable from 'components/tables/BaseTable'
import FiltersWrapper from 'components/tables/FiltersWrapper'
import MainLayout from 'components/templates/MainLayout'
import FilterSelect from 'components/tables/FilterSelect'
import PageLoader from 'components/PageLoader'
import FilterSearch from 'components/tables/FilterSearch'

const getHeaders = (month, year) => [
  {
    label: 'Imie i nazwisko',
    type: 'computed',
    propName: 'not_used',
    fn: (user) => (
      <Link
        component={RouterLink}
        to={routes.adminBillingsUserDetails.replace(':userId', user.id).replace(':month', month).replace(':year', year)}
        level="title-sm"
      >
        <Typography color="primary" level="body-xs">
          {user.computedFullName}
        </Typography>
      </Link>
    ),
  },
  {
    label: 'Ilość zadań w miesiącu',
    propName: 'taskCount',
    rowClassName: 'text-center',
    className: 'text-center width-xl',
  },
  {
    label: 'Wynagrodzenie - niezaakceptowane',
    propName: 'pendingAmount',
    type: 'money',
    rowClassName: 'text-right',
    className: 'text-right',
  },
  {
    label: 'Wynagrodzenie - zaakceptowane',
    propName: 'acceptedAmount',
    type: 'money',
    rowClassName: 'text-right',
    className: 'text-right',
  },
  {
    label: 'Wynagrodzenie - suma',
    propName: 'totalAmount',
    type: 'money',
    rowClassName: 'text-right',
    className: 'text-right',
  },
]

const AdminBillingsView = () => {
  const pagination = usePagination()
  const dispatch = useDispatch()
  const { yearsOptions, monthsOptions, currentYear, currentMonth } = useDateFilters()
  // const [innerLoading, setInnerLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [month, setMonth] = useState(currentMonth)
  const [year, setYear] = useState(currentYear)

  const { usersBillings, loading, error } = useSelector((s) => s.billings)

  const params = useMemo(() => ({ search: search || null, month, year }), [search, month, year])

  useEffect(() => {
    dispatch(getUsersBillings({ params }))
    return () => dispatch(cleanupRequestStatus())
  }, [dispatch, params])

  if (loading)
    return (
      <MainLayout>
        <PageLoader />
      </MainLayout>
    )

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs items={[{ label: 'Rozliczenia' }]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Rozliczenia
        </Typography>
      </Box>

      <FiltersWrapper
        renderSearch={<FilterSearch label="Wyszukiwanie użytkownika" value={search} setValue={setSearch} />}
        renderFilters={
          <>
            <FilterSelect
              label="Miesiąc"
              placeholder="Miesiąc"
              options={monthsOptions}
              value={month}
              setValue={setMonth}
              allowDelete={false}
              sx={{ ml: { sm: 'auto' } }}
            />
            <FilterSelect
              label="Rok"
              placeholder="Rok"
              options={yearsOptions}
              value={year}
              setValue={setYear}
              allowDelete={false}
            />
          </>
        }
      />

      <BaseTable
        headers={getHeaders(month, year)}
        data={usersBillings}
        offset={(pagination.values.currentPage - 1) * pagination.values.perPage}
        noDataText="Brak rozliczeń do wyświetlenia."
      />

      {error && (
        <Alert color="danger" variant="soft" startDecorator={<Warning />}>
          {error}
        </Alert>
      )}
    </MainLayout>
  )
}

export default AdminBillingsView
