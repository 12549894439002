import { Dashboard, FormatListBulleted, Group, Payments, Settings, TableChart } from '@mui/icons-material'
import routes from 'routes'
// eslint-disable-next-line no-undef
export const API_BASE_URL = process.env.REACT_APP_SERVER_URL
export const ACCESS_TOKEN_NAME = 'vistula_access_token'
export const DATE_FORMAT = 'DD.MM.YYYY'

export const APP_LAUNCH_YEAR = 2024

export const ROLES = {
  user: {
    value: 'USER',
    label: 'Użytkownik',
  },
  admin: {
    value: 'ADMIN',
    label: 'Administrator',
  },
}

export const STATUS = {
  open: {
    value: 'open',
    label: 'W trakcie',
  },
  closed: {
    value: 'closed',
    label: 'Zakończony',
  },
  cancelled: {
    value: 'cancelled',
    label: 'Anulowano',
  },
  pending: {
    value: 'pending',
    label: 'W trakcie',
  },
  approved: {
    value: 'approved',
    label: 'Zaakceptowane',
  },
  notapproved: {
    value: 'notapproved',
    label: 'Niezaakceptowane',
  },
  done: {
    value: 'done',
    label: 'Zakończono',
  },
}

export const MAIN_MENU = {
  [ROLES.user.value]: [
    { label: 'Dashboard', icon: <Dashboard fontSize="small" />, to: routes.user, end: true },
    { label: 'Projekty', icon: <TableChart fontSize="small" />, to: routes.userProjects },
    {
      label: 'Moje zadania',
      icon: <FormatListBulleted fontSize="small" />,
      to: routes.userMyTasks,
      injectUsersTasksCount: true,
    },
    { label: 'Rozliczenia', icon: <Payments fontSize="small" />, to: routes.userBillings },
  ],
  [ROLES.admin.value]: [
    // { label: 'Dashboard', icon: <Dashboard fontSize="small" />, to: routes.admin, end: true },
    { label: 'Projekty', icon: <TableChart fontSize="small" />, to: routes.adminProjects },
    { label: 'Rozliczenia', icon: <Payments fontSize="small" />, to: routes.adminBillings },
    { label: 'Użytkownicy', icon: <Group fontSize="small" />, to: routes.adminUsers },
    { label: 'Ustawienia aplikacji', icon: <Settings fontSize="small" />, to: routes.adminSettings },
  ],
}

export const newTaskObject = {
  date: '',
  hours: '',
  hoursCount: '',
  localization: '',
  notes: '',
  lp: '',
}

export const dateFilters = {
  current: { value: 'current', label: 'Bieżące' },
  all: { value: 'all', label: 'Wszystkie' },
}
export const dateFiltersOptions = Object.values(dateFilters)

export const openFilters = {
  open: { value: 'open', label: 'Otwarte' },
  all: { value: 'all', label: 'Wszystkie' },
}
export const openFiltersOptions = Object.values(openFilters)
